:root {
  --color-main: #4c4c4c;
  --color-header: #4c4c4c;
  --color-error: #f25c28;
  --color-bk-panel: #f4f8e8;
  --color-section: #ededed;;
}

body {
  margin: 0;
  /* min-height: 100vh; */
  font-family: 'Nunito Sans', Roboto, arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.copyright {
  background-color: #162731;
  margin: 0px;
  padding: 10px;
  color: #ffffff;
  font-size: smaller;
  flex-shrink: 0;
}

/* Bootstrap container: */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

@media (max-width: 640px) {
  .container-fluid {
    width: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
}



@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

header {
  width: 100%;
  padding: 20px 0px;
  margin-bottom: 20px;
}

header h1 {
  margin: 0px auto;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold; 
}
header .intro {
  margin: 0px auto;
  text-align: center;
  width: 100%;
  font-size: 1rem;
}


.section {
  margin-bottom: 20px;
}

.section .title {
  padding: 15px 20px;
  padding-left: 60px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nunito Sans', arial;
  font-weight: bold;
  text-align: left;
  align-items: center;
}

.section .section-row { display: flex; background: var(--color-section); }
.section .pad { padding: 2px 20px; display: flex; align-items: center; width: 100%; }
.section .column { padding: 20px 30px; flex: 1; }
.section .small-column { padding: 20px; }
.section .label { color: #000; font-size: 16px; font-weight: 500; font-family: 'Nunito Sans', arial; text-align: left; margin-top:10px; margin-bottom: 10px; }
.section .pad .label { flex: 1; font-family: 'Nunito Sans', arial;  }

@media(max-width: 480px) {
  .section .pad { display: block; padding-right: 0px; width: auto; }
  .section .label { padding-top:10px; margin-bottom: 0px; }
  .section .pad .label { flex: none; width: auto; }
  .section .section-row .column {
    margin: 0px !important;
    padding: 10px 20px !important;
  }
  .section .ranger {
    margin-top: 20px;
    margin-bottom: 15px;
    left: 5% !important;
    width: 85% !important;
  }
}

@media(max-width: 800px) {
  .section-row { display: block; }
  .small-column, .column { padding-top: 10px; padding-bottom: 10px; }
}


.level-container .subtitle {
  max-width: 150px;
}

/** DEFAULT (NEWLEAF) */
.theme-default header {
  background: #4c4c4c;
}
.theme-default header h1 {
  color: #ffdd03; 
}
.theme-default .section .title {
  color: #ffdd03;
  background: #4c4c4c url(../assets/loan2020.png) no-repeat 0px 0px;
  background-size: 40px;
  background-position: 10px 8px;
}
.theme-default .input-wrapper {
  border: 1px #000 solid;
}

.theme-default .ranger-wrapper .touch {
  background: #ffdd03;
  color: white;
}
.theme-default .ranger-wrapper .value {
  background: #ffdd03;
  color: #000;
}
.theme-default .ranger-wrapper .line {
  background: #ffdd03;
}
.theme-default .ranger-wrapper .arrow svg path {
  fill: #ffdd03;
}
.theme-default .level-container .title {
  color:#000;
}
.theme-default .level-container .subtitle {
  color:#000;
}
.theme-default .level-container .amount {
  color:#000;
}
.theme-default svg.icon-info path.main {
  fill: #4c4c4c;
}
.theme-default .level-container .person {
  width: 75px;
  height: 215px;
}
.theme-default .level-container .person-0 {
  background: url(../assets/default/level-0.png) no-repeat center bottom;
}
.theme-default .level-container .person-1 {
  background: url(../assets/default/level-1.png) no-repeat center bottom;
}
.theme-default .level-container .person-2 {
  background: url(../assets/default/level-2.png) no-repeat center bottom;
}

/* LTV laterlivingnow */

.theme-laterlivingnow .intro {
  color: #fff;
}
.theme-laterlivingnow header {
  background: #4c4c4c;
}
.theme-laterlivingnow header h1 {
  color: #fff; 
}
.theme-laterlivingnow .section .title {
  color: #fff;
  background: #4c4c4c url(../assets/loan2019.png) no-repeat 0px 0px;
  background-size: 40px;
  background-position: 10px 8px;
}
.theme-laterlivingnow .input-wrapper {
  border: 1px #000 solid;
}

.theme-laterlivingnow .ranger-wrapper .touch {
  background: #65a329;
  color: white;
}
.theme-laterlivingnow .ranger-wrapper .value {
  background: #65a329;
  color: #fff;
}
.theme-laterlivingnow .ranger-wrapper .line {
  background: #65a329;
}
.theme-laterlivingnow .ranger-wrapper .arrow svg path {
  fill: #65a329;
}
.theme-laterlivingnow .level-container .title {
  color:#000;
}
.theme-laterlivingnow .level-container .subtitle {
  color:#000;
}
.theme-laterlivingnow .level-container .amount {
  color:#000;
}
.theme-default svg.icon-info path.main {
  fill: #4c4c4c;
}
.theme-laterlivingnow .level-container .person {
  width: 75px;
  height: 250px;
}

.theme-laterlivingnow .level-container .person-0 {
  background: url(../assets/laterlivingnow/level-0.png) no-repeat center bottom;
}
.theme-laterlivingnow .level-container .person-1 {
  background: url(../assets/laterlivingnow/level-1.png) no-repeat center bottom;
}
.theme-laterlivingnow .level-container .person-2 {
  background: url(../assets/laterlivingnow/level-2.png) no-repeat center bottom;
}

/** MIDLANDS */
.theme-lightblue header {
  background: white;
  color: #00b0f0;
}
.theme-lightblue header * {
  font-weight: bold;
}
.theme-lightblue .section .title {
  color: #00b0f0;
  border: 3px #00b0f0 solid;
  font-size: 1.2rem;
  font-weight: bold;
  background: #fff url(../assets/loan-lightblue.png) no-repeat 0px 0px;
  background-size: 40px;
  background-position: 10px 12px;
}
.theme-lightblue .section .section-row {
  background: #e5f7fd;
}
.theme-lightblue .input-wrapper {
  border: 2px #8c9599 solid;
}
.theme-lightblue .ranger-wrapper .touch {
  background: #00b0f0;
  color: white;
}
.theme-lightblue .ranger-wrapper .value {
  background: #00b0f0;
  color: white;
}
.theme-lightblue .ranger-wrapper .line {
  background: #00b0f0;
}
.theme-lightblue .ranger-wrapper .arrow svg path {
  fill: #00b0f0;
}
.theme-lightblue .level-container .amount {
  color:#00b0f0;
}
.theme-lightblue svg.icon-info path.main {
  fill: #00b0f0;
}
.theme-lightblue .level-container .person {
  width: 75px;
  height: 240px;
}
.theme-lightblue .level-container .person-0 {
  background: url(../assets/lightblue/level-0.svg) no-repeat center bottom;
}
.theme-lightblue .level-container .person-1 {
  width: 80px;
  background: url(../assets/lightblue/level-1.svg) no-repeat center bottom;
}
.theme-lightblue .level-container .person-2 {
  width: 80px;
  background: url(../assets/lightblue/level-2.svg) no-repeat center bottom;
}

.theme-magenta {
  background: #572F7F;
}
.theme-magenta .title,
.theme-magenta .subtitle,
.theme-magenta .amount,
.theme-magenta .pct,
.theme-magenta .disclaimer {
  color: white !important; 
}

/** releasemyequity **/
.theme-magenta header {
  background: #572F7F;
  color: white; 
  margin-bottom: 40px;
}
.theme-magenta header * {
  font-weight: bold;
}
.theme-magenta .section .label {
  color: #572F7F;
}
.theme-magenta .section .title {
  color: #fff;
  background: #572F7F url(../assets/loan2019.png) no-repeat 0px 0px;
  background-size: 40px;
  background-position: 10px 12px;
  font-size: 1.2rem;
  font-weight: bold;
}
.theme-magenta .section .section-row {
  background: #f6f3f9;
}
.theme-magenta .input-wrapper {
  border: 2px #572F7F solid;
}
.theme-magenta .input-wrapper input { 
  color: #572F7F;
}
.theme-magenta .ranger-wrapper .touch {
  background: #572F7F;
  color: white;
}
.theme-magenta .ranger-wrapper .value {
  background: #572F7F;
  color: white;
}
.theme-magenta .ranger-wrapper .line {
  background: #572F7F;
}
.theme-magenta .ranger-wrapper .arrow svg path {
  fill: #572F7F;
}
.theme-magenta .level-container .title {
  color: #572F7F;
}
.theme-magenta .level-container .subtitle {
  color: #572F7F;
}
.theme-magenta .level-container .disclaimer {
  color: #572F7F;
}
.theme-magenta .level-container .pct {
  color: #572F7F;
}
.theme-magenta svg.icon-info path.main {
  fill: #572F7F;
}
.theme-magenta .level-container .person {
  width: 75px;
  height: 240px;
}
.theme-magenta .level-container .person-0 {
  background: url(../assets/magenta/level-0.svg) no-repeat center bottom;
}

.theme-magenta .level-container .person-1 {
  width: 80px;
  background: url(../assets/magenta/level-1.svg) no-repeat center bottom;
}
.theme-magenta .level-container .person-2 {
  width: 80px;
  background: url(../assets/magenta/level-2.svg) no-repeat center bottom;
}

/** retirementsolutions **/
.theme-cyanmagenta header {
  background: #2a2460;
  color: white;
  margin-bottom: 40px;
}
.theme-cyanmagenta header * {
  font-weight: bold;
}
.theme-cyanmagenta .section .label {
  color: #3c376d;
}
.theme-cyanmagenta .section .title {
  color: #fff;
  background: #00add6 url(../assets/loan2019.png) no-repeat 0px 0px;
  background-size: 40px;
  background-position: 10px 12px;
  font-size: 1.2rem;
  font-weight: bold;
}
.theme-cyanmagenta .section .section-row {
  background: #f6f3f9;
}
.theme-cyanmagenta .input-wrapper {
  border: 2px #00add6 solid;
}
.theme-cyanmagenta .input-wrapper input { 
  color: #00add6;
}
.theme-cyanmagenta .ranger-wrapper .touch {
  background: #00add6;
  color: white;
}
.theme-cyanmagenta .ranger-wrapper .value {
  background: #00add6;
  color: white;
}
.theme-cyanmagenta .ranger-wrapper .line {
  background: #00add6;
}
.theme-cyanmagenta .ranger-wrapper .arrow svg path {
  fill: #00add6;
}
.theme-cyanmagenta .level-container .title {
  color: #2a2460;
}
.theme-cyanmagenta .level-container .subtitle {
  color: #2a2460;
}
.theme-cyanmagenta .level-container .disclaimer {
  color: #2a2460;
}
.theme-cyanmagenta .level-container .pct {
  color: #2a2460;
}
.theme-cyanmagenta .level-container .hint {
  color: #2a2460;
}
.theme-cyanmagenta svg.icon-info  .main {
  fill: #2a2460;
}
.theme-cyanmagenta .level-container .person {
  width: 75px;
  height: 240px;
}
.theme-cyanmagenta .level-container .person-0 {
  background: url(../assets/cyanmagenta/level-0.svg) no-repeat center bottom;
}
.theme-cyanmagenta .level-container .person-1 {
  width: 80px;
  background: url(../assets/cyanmagenta/level-1.svg) no-repeat center bottom;
}
.theme-cyanmagenta .level-container .person-2 {
  width: 80px;
  background: url(../assets/cyanmagenta/level-2.svg) no-repeat center bottom;
}




.theme-purplegreen header {
  background: #6a2f88;
  color: white; 
  margin-bottom: 40px;
}
.theme-purplegreen header * {
  font-weight: bold;
}
.theme-purplegreen .section .label {
  color: #4c4c4c;
}
.theme-purplegreen .section .title {
  color: #fff;
  background: #6a2f88 url(../assets/loan2019.png) no-repeat 0px 0px;
  background-size: 40px;
  background-position: 10px 12px;
  font-size: 1.2rem;
  font-weight: bold;
}
.theme-purplegreen .section .section-row {
  background: #f6f3f9;
}
.theme-purplegreen .input-wrapper {
  border: 2px #6a2f88 solid;
}
.theme-purplegreen .input-wrapper input { 
  color: #541b82;
}
.theme-purplegreen .ranger-wrapper .touch {
  background: #1fa337;
  color: white;
}
.theme-purplegreen .ranger-wrapper .value {
  background: #1fa337;
  color: white;
}
.theme-purplegreen .ranger-wrapper .line {
  background: #1fa337;
}
.theme-purplegreen .ranger-wrapper .arrow svg path {
  fill: #1fa337;
}
.theme-purplegreen .level-container .title {
  color: #6a2f88;
}
.theme-purplegreen .level-container .subtitle {
  color: #6a2f88;
}
.theme-purplegreen .level-container .disclaimer {
  color: #6a2f88;
}
.theme-purplegreen .level-container .pct {
  color: #6a2f88;
}
.theme-purplegreen svg.icon-info path.main {
  fill: #6a2f88;
}
.theme-purplegreen .level-container .person {
  width: 75px;
  height: 240px;
}
.theme-purplegreen .level-container .person-0 {
  background: url(../assets/purplegreen/level-0.svg) no-repeat center bottom;
}

.theme-purplegreen .level-container .person-1 {
  width: 80px;
  background: url(../assets/purplegreen/level-1.svg) no-repeat center bottom;
}
.theme-purplegreen .level-container .person-2 {
  width: 80px;
  background: url(../assets/purplegreen/level-2.svg) no-repeat center bottom;
}

